import React from 'react'

import SEO from '../components/seo'
import Layout from '../components/layout'
import About from '../components/About'

const AboutPage = () => (
    <Layout subpage>
        <SEO title="O mně" />
        <About />
    </Layout>
)

export default AboutPage
