import React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { RichText } from 'prismic-reactjs'

const AboutWrapper = styled.div`
    padding: 4rem 0;

    ${({ theme }) => theme.media.md`
        padding: 7.5rem 0;
   `}

    ${({ theme }) => theme.media.sm`
        h3 {
            transform: translate(-5.5rem, 0);
        }
   `}

   img {
        width: 27.5rem;
    }
`;

const About = () => {
    const data = useStaticQuery(graphql`
        {
            prismic {
                allAbouts {
                    edges {
                        node {
                            content
                            photo
                            subtitle
                            title
                        }
                    }
                }
            }
        }
    `)

    const { title, subtitle, content, photo } = data.prismic.allAbouts.edges[0].node

    return (
        <AboutWrapper>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 text-center mb-5 mb-md-0">
                        <img src={photo.url} alt={photo.alt} />
                    </div>
                    <div className="col-md-5 offset-md-1 d-flex flex-column justify-content-center">
                        <h2 className="subtitle mb-5">{RichText.asText(title)}</h2>
                        <h3 className="hero-text mb-5">{RichText.asText(subtitle)}</h3>
                        <RichText render={content} />
                    </div>
                </div>
            </div>
        </AboutWrapper>
    )
}

export default About
